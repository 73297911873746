















































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { OnboardService } from '@services/onboard.service';
import { CB_CREATE_CUSTOMER, CB_SubOnboard } from '../utils/apptweakqueries';
import { LSS } from '../core/services';
import SigninAppleBtn from '../components/oauth/SigninAppleBtn.vue';
import { LoginService } from '@services/login.service';
import { decodeToken } from '@shared/utils/commom.util';
import VueMask from 'v-mask';
import { Kochava } from 'kochava';
import LogRocket from 'logrocket';

Vue.use(VueMask);
export default Vue.extend({
  name: 'Onboard',
  props: {
    kochava: Kochava,
  },
  created() {
  },
  data() {
    return {
      phoneNumberError: '',
      ctaChecked: false,
      savingPersonalDetails: false,
      saving: false,
      onBoardDetails: {
        name: decodeToken(LSS.token).FirstName,
        surname: decodeToken(LSS.token).LastName,
        phone: decodeToken(LSS.token).Phone,
        company: decodeToken(LSS.token).CompanyName,
        job: decodeToken(LSS.token).JobTitle,
      },
      dialog: false,
      step: 1,
      isStaff: false,
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },

      phoneProps: {
        autoFormat: true,
        mode: 'international',
        preferredCountries: ['US'],
        validCharactersOnly: true,
      },
      items: [
        {
          avatar: '../assets/images/apple.svg',
          title: 'Apple Search Ads Funnel Track',
          subtitle:
            'Track your entire user acquisition funnel with crucial metrics such as ROAS, ARPU, CPI, and revenue. See exactly how much you spend and how much you get in return on a single dashboard.',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Custom Goal Tracking',
          subtitle: `Determine your own goals based on your KPIs and track them to see your real performance. Choose any in-app event as your goal and analyze the effect of your campaign efforts on your custom goals.`,
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Organic Keyword Hunt',
          subtitle:
            'Uncover the organic keywords that your competitors are ranking for. Analyze your competitors’ rankings, popularity, and difficulty of each keyword to immediately run ads on them.',
        },
      ],
      isFormValid: false,
      nameRules: [
        (v) => (!!v && !!v.trim()) || 'First Name is required!',
        (v) => (v && v.length <= 50) || this.$t('createCampaignRules.textMax'),
        (v) => (v && v.length >= 2) || this.$t('createCampaignRules.textMin'),
        (v) => !/[<>;\\[\]{}&%]/g.test(v) || this.$t('createCampaignRules.specialCharNotAllowed'),
        (v) => !/\s\s/.test(v) || this.$t('createCampaignRules.onlySinglespaceAllowed'),
      ],

      surnameRules: [
        (v) => !/[<>;\\[\]{}&%]/g.test(v) || this.$t('createCampaignRules.specialCharNotAllowed'),
        (v) => !/\s\s/.test(v) || this.$t('createCampaignRules.onlySinglespaceAllowed'),
      ],
      phoneRules: [(v) => (!!v && !!v.trim()) || 'Phone is required!'],
      companyRules: [
        (v) => (!!v && !!v.trim()) || 'Company Name is required!',
        (v) => (v && v.length <= 50) || this.$t('createCampaignRules.textMax'),
        (v) => (v && v.length >= 2) || this.$t('createCampaignRules.textMin'),
        (v) => !/[<>;\\[\]{}&%]/g.test(v) || this.$t('createCampaignRules.specialCharNotAllowed'),
        (v) => !/\s\s/.test(v) || this.$t('createCampaignRules.onlySinglespaceAllowed'),
      ],
      jobRules: [
        (v) => (!!v && !!v.trim()) || 'Job Title is required!',
        (v) => (v && v.length <= 50) || this.$t('createCampaignRules.textMax'),
        (v) => (v && v.length >= 2) || this.$t('createCampaignRules.textMin'),
        (v) => !/[<>;\\[\]{}&%]/g.test(v) || this.$t('createCampaignRules.specialCharNotAllowed'),
        (v) => !/\s\s/.test(v) || this.$t('createCampaignRules.onlySinglespaceAllowed'),
      ],
      ctaRules: [(v) => !!v || 'You must accept the license agreement'],
    };
  },
  components: {
    SigninAppleBtn,
  },
  computed: {
    c: function (): string {
      return '';
    },
  },

  methods: {
    onInput: function (number, phoneObject) {
      const cleanedNumber = number.replace(/[^0-9+]/g, '');
      const plusCount = (cleanedNumber.match(/\+/g) || []).length;
      console.log('cleanedNumber', cleanedNumber);
      if (plusCount <= 1 && /^[0-9+() -]+$/.test(cleanedNumber)) {
        this.phone.number = phoneObject.formatted;
        this.phone.valid = phoneObject.valid;
        this.onBoardDetails.phone = phoneObject.formatted;
        this.phoneNumberError = ''; // Clear the error message
      } else {
        if (cleanedNumber != '') {
          this.phone.valid = false;
          this.phoneNumberError = this.$t('createCampaignRules.invalidPhoneFormat');
          return false;
        }
      }
    },
    async save() {
      this.savingPersonalDetails = true;
      await OnboardService.save(decodeToken(LSS.token).UserId, {
        FirstName: this.onBoardDetails.name,
        LastName: this.onBoardDetails.surname,
        Phone: this.onBoardDetails.phone,
        CompanyName: this.onBoardDetails.company,
        JobTitle: this.onBoardDetails.job,
        agreementTimeStamp: Date.now(),
      })
        .then(async (res) => {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            await this.createSubPlan();
          }
          const user = await this.getUserDetails(decodeToken(LSS.token).UserId);
          this.savingPersonalDetails = false;
          this.$router.push('/welcome-onboard');
        })
        .catch((err) => {
          this.savingPersonalDetails = false;
          this.saving = false;
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },

    //For Chargebee 2.0
    async createSubPlan() {
      await this.$apollo
        .query({
          query: CB_CREATE_CUSTOMER,
          variables: {
            first_name: this.onBoardDetails.name,
            last_name: this.onBoardDetails.surname,
            cust_id: decodeToken(LSS.token).TeamID,
            email: decodeToken(LSS.token).Email,
            company: this.onBoardDetails.company,
          },
        })
        .then((data) => {
        })
        .catch((error) => {
        });
    },

    async getUserDetails(UserID: string) {
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
      });
      const user = decodeToken(token);
      LSS.token = token;
      this.$root.$emit('userNameFetched', user);
      return user;
    },
  },

  beforeMount() {
    if (decodeToken(LSS.token).Role == 'STAFF') {
      this.isStaff = true;
    }
  },

  mounted() {
    this.dialog = true;
    if (window.location.host == 'app.searchadsmaven.com') {
      LogRocket.init('f59ovk/search-ads-maven', {
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase().indexOf('onboard/save') !== -1) {
              request.body = null;
            }
            if (request.url.toLowerCase().indexOf('auth/signup-ory') !== -1) {
              request.body = null;
            }

            if (request.url.toLowerCase().indexOf('self-service/login') !== -1) {
              request.body = null;
            }
            return request;
          },
        },
      });
    }
  },
});
