<template>
  <div>
    <v-row>
      <v-col>
        <button :disabled="loadingState" @click="getOAuthUrl()">
          <img alt="Apple" src="https://appleid.cdn-apple.com/appleid/button" />
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { GET_OAUTH_URL } from 'src/utils/apollo-queries';

export default Vue.extend({
  name: 'SigninAppleBtn',
  data() {
    return {
      loadingState: false,
    };
  },
  methods: {
    async getOAuthUrl() {
      this.loadingState = true;
      try {
        const result = await this.$apollo.query({
          query: GET_OAUTH_URL,
          variables: {
            req: { type: 'APPLE_SEARCH_ADS' }
          }
        });
        location.href = result.data.getOAuthState.url;
      } catch (error) {
        this.$notify.error(this.$t('successMsg.signinFailMsg'));
      }

      this.loadingState = false;
    },
  },
});
</script>

<style>
button[disabled] > img {
  opacity: 0.6;
  transition: all 0.2s;
}
</style>
