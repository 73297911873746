var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c(
              "button",
              {
                attrs: { disabled: _vm.loadingState },
                on: {
                  click: function ($event) {
                    return _vm.getOAuthUrl()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    alt: "Apple",
                    src: "https://appleid.cdn-apple.com/appleid/button",
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }