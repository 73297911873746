var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { elevation: "0" } },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                },
                [
                  _c("h5", { staticClass: "text-h6" }, [
                    _vm._v(" " + _vm._s(_vm.$t("onboard.welcome")) + " "),
                  ]),
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "rounded-0 pt-10" },
                [
                  _c(
                    "v-container",
                    { staticClass: "stepper-template pa-0" },
                    [
                      [
                        _c(
                          "v-card",
                          { staticClass: " ", attrs: { elevation: "0" } },
                          [
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.isFormValid,
                                  callback: function ($$v) {
                                    _vm.isFormValid = $$v
                                  },
                                  expression: "isFormValid",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          lg: "6",
                                          md: "6",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: " ",
                                          attrs: {
                                            label: "First Name",
                                            placeholder: "Enter first name",
                                            rules: _vm.nameRules,
                                            required: "",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.onBoardDetails.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.onBoardDetails,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "onBoardDetails.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          lg: "6",
                                          md: "6",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Last Name",
                                            placeholder: "Enter last name",
                                            rules: _vm.surnameRules,
                                            required: "",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.onBoardDetails.surname,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.onBoardDetails,
                                                "surname",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "onBoardDetails.surname",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0 mb-5",
                                        attrs: {
                                          cols: "12",
                                          lg: "6",
                                          md: "6",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "vue-tel-input",
                                          _vm._b(
                                            {
                                              staticClass: "mb-1",
                                              class: {
                                                "error-border":
                                                  _vm.phoneNumberError,
                                              },
                                              on: { input: _vm.onInput },
                                              model: {
                                                value: _vm.onBoardDetails.Phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "Phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.Phone",
                                              },
                                            },
                                            "vue-tel-input",
                                            _vm.phoneProps,
                                            false
                                          )
                                        ),
                                        _vm.phoneNumberError
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "error--text text-caption ml-3 v-messages__message",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.phoneNumberError)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          lg: "6",
                                          md: "6",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        !_vm.isStaff
                                          ? _c("v-text-field", {
                                              attrs: {
                                                label: "Company Name",
                                                placeholder:
                                                  "Enter your Company Name",
                                                rules: _vm.companyRules,
                                                required: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.onBoardDetails.company,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.onBoardDetails,
                                                    "company",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "onBoardDetails.company",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          lg: "6",
                                          md: "6",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Job Title",
                                            placeholder: "Enter job title",
                                            rules: _vm.jobRules,
                                            required: "",
                                            outlined: "",
                                            dense: "",
                                          },
                                          model: {
                                            value: _vm.onBoardDetails.job,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.onBoardDetails,
                                                "job",
                                                $$v
                                              )
                                            },
                                            expression: "onBoardDetails.job",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          cols: "12",
                                          lg: "12",
                                          md: "12",
                                          sm: "12",
                                        },
                                      },
                                      [
                                        _c("v-checkbox", {
                                          staticClass: "pt-0 mt-0",
                                          attrs: { rules: _vm.ctaRules },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _vm._v(
                                                        " I have thoroughly read and accept the terms and conditions in the "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "a",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          target:
                                                                            "_blank",
                                                                          href:
                                                                            "https://www.kochava.com/license-service-agreement/",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " License Agreement "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        },
                                                        [
                                                          _vm._v(
                                                            " View license agreement "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                          model: {
                                            value: _vm.ctaChecked,
                                            callback: function ($$v) {
                                              _vm.ctaChecked = $$v
                                            },
                                            expression: "ctaChecked",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-actions",
                                  { staticClass: "justify-end mt-0 pa-0" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          width: "127",
                                          color: "primary",
                                          depressed: "",
                                          large: "",
                                          loading: _vm.savingPersonalDetails,
                                          disabled:
                                            !_vm.isFormValid ||
                                            !_vm.phone.valid,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.save()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("addnKeys.save")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text-center my-2" }),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }